<template>
  <div class="w-full sm:w-1/2 px-4 mb-4">
    <vx-card title="Transaction History">
      <template slot="no-body">
        <rj-select
          v-model="filter.transactionType"
          name="transaction_filter"
          :options="transactionTypes"
          :clearable="false"
          placeholder="Filter by transaction type..."
          class="w-1/2 pl-5 pt-2"
        />
      </template>
      <template slot="no-body">
        <vs-table
          :data="filteredTransactions"
          class="mt-4"
          pagination
          :max-items="itemsPerPage"
          :no-data-text="''"
          @selected="jumpToTransaction"
        >
          <template slot="thead">
            <vs-th sort-key="created">
              DATE
            </vs-th>
            <vs-th sort-key="type">
              TYPE
            </vs-th>
            <vs-th sort-key="amount_due">
              AMOUNT
            </vs-th>
            <vs-th sort-key="status">
              STATUS
            </vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr
              v-for="transaction in data"
              :key="transaction.id"
              :data="transaction"
            >
              <vs-td>
                {{ transaction.date }}
              </vs-td>
              <vs-td>
                {{ transaction.type }}
              </vs-td>
              <vs-td>
                {{ transaction.total }}
              </vs-td>
              <vs-td>
                {{ transaction.status }}
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </template>
    </vx-card>
  </div>
</template>

<script>
import Color from 'color';
import { colors as themeColors } from '@/../themeConfig';
import { formatCentsAsDollars } from '@/utils';

export default {
  name: 'RjTransactionsList',
  props: {
    transactions: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      themeColors,
      Color,
      itemsPerPage: 10,
      transactionTypes: [
        'All',
        'Invoice',
        'Commission Statement',
      ],
      filter: {
        transactionType: '',
      },
    };
  },
  computed: {
    currentRole() {
      return this.$store.getters['auth/currentRole'];
    },
    hasValidPaymentMethod() {
      return this.$store.getters['partners/hasValidPaymentMethod'];
    },
    partner() {
      return this.$store.getters['partners/partner'];
    },
    filteredTransactions() {
      if (this.filter.transactionType === 'All') {
        return this.transactions;
      }
      if (this.filter.transactionType) {
        return this.transactions.filter((t) => t.type === this.filter.transactionType);
      }
      return this.transactions;
    },
  },
  methods: {
    formatCentsAsDollars,
    /**
     * Navigates to the given invoice page.
     *
     * @param  {Object} transaction
     * @return {void}
     */
    async jumpToTransaction({ id, type }) {
      if (/^Partner/.test(this.currentRole) && !this.hasValidPaymentMethod) {
        return;
      }
      const params = (type === 'Invoice') ? { invoice: id, partner: this.partner.id } : { statement: id, partner: this.partner.id };
      const name = (type === 'Invoice') ? 'partner-invoice' : 'partner-commission-statement';
      const route = this.$router.resolve({ name, params });
      window.open(route.href, '_blank');
    },
  },
};
</script>
